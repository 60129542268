import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
//import { trackPageview, enableAutoPageviews } from 'plausible-tracker';

// Initialize with your domain
//trackPageview({ domain: 'starkindustries.io' });
//enableAutoPageviews();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
